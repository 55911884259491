import React from 'react'

import Layout from 'components/layout'
import SEO from 'components/seo'
import Terms from 'components/Terms'

const TermsOfUse = () => {
  return (
    <Layout>
      <SEO title="Terms" />
      <Terms name="TERMS_OF_USE" split />
    </Layout>
  )
}

export default TermsOfUse
